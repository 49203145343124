@import 'src/styles/colorVariables.scss';

.topBar {
  position: sticky;
  top: 0;
  z-index: 999;
  height: 73px;
  display: flex;
  justify-content: space-between;
  padding: 15px 30px 5px;
  background: #eef1fe;
}

.topBar.scrolled {
  border-bottom: 1px solid #d4dbfc;
}

.accpanel {
  height: 38px;
}

.right {
  margin-left: auto;
  display: flex;
  align-items: center;
}

@import 'src/styles/colorVariables.scss';

.mentionsChart {
  width: 172px;
  height: 172px;
  margin-right: 40px;
  position: relative;
}

.label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
}

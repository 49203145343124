@import 'src/styles/colorVariables.scss';
@import 'src/styles/variables.scss';

.filterSearchDropdownWithTabs {
  width: 100%;
  border-radius: 10px;
  border: 1px solid $hawkes-blue;
  background: $white;

  & + & {
    margin-top: 14px;
  }
}

.inputWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  cursor: pointer;

  input {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.14px;
    color: $malibu;
    flex: 1;

    &::placeholder {
      color: $regent-gray;
    }
  }
}

.selectedLabel {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
  color: $shark;
}

.selectedLabelCount {
  color: $malibu;
}

.sectionTabs {
  position: relative;
  display: flex;
  align-items: center;
  padding: 14px 14px 0;
  margin-bottom: 10px;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: rgba(200, 202, 255, 0.5);
  }
}

.tab {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.14px;
  display: flex;
  align-items: center;
  padding-bottom: 14px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border-bottom: 2px solid transparent;
  color: $regent-gray;

  & + & {
    margin-left: 28px;
  }
}

.active {
  color: $shark;
  border-bottom: 2px solid $shark;
}

.dropdown {
  width: 100%;
  height: 220px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid;
  border-top-color: transparent;
  border-right-color: $hawkes-blue;
  border-bottom-color: $hawkes-blue;
  border-left-color: $hawkes-blue;
  overflow: hidden;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: $titan-white;
  margin: 4px 0;
}

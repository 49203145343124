@import 'src/styles/colorVariables.scss';

.dailyTrendsSection {
  background-color: $malibu;
  border-radius: 24px;
  margin: 15px auto;
  max-width: 1170px;
  padding: 24px;
  position: relative;
}

.tooltip {
  margin-left: 8px;
  display: flex;
  align-items: center;
}

.chartContainer {
  padding-top: 20px;
  height: 336px;
  overflow: hidden;
}

.footer {
  color: $white;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.41px;
  margin: 0 46px;
}

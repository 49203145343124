@import 'src/styles/colorVariables.scss';

.input {
  display: block;
  width: 100%;
  height: 48px;
  color: $manatee;
  background-color: $white;
  height: 48px;
  border: 1px solid $hawkes-blue;
  padding: 13px;
  font-family: Manrope-600;
  font-size: 12px;
  line-height: 22px;
  font-weight: 600;
  color: $manatee;
  border-radius: 10px;
}

@import 'src/styles/colorVariables.scss';
@import 'src/styles/variables.scss';

.addNewKeywordModalContent {
  min-width: 512px;
}

.modalHeader {
  padding: 16px;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  border-bottom: 1px solid $hawkes-blue;
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 8px;
}

.modalBody {
  overflow-y: scroll;
  padding-top: 16px;

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
}

.inputWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.input {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  display: block;
  width: 100%;
}

.list {
  max-height: 42vh;
  overflow-y: scroll;
  padding-top: 16px;

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
}

.modalFooter {
  padding: 16px 0;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid $hawkes-blue;
}

.cancel {
  margin-right: 16px;
}

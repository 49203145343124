@import 'src/styles/variables.scss';
@import 'src/styles/colorVariables.scss';
@import 'src/styles/mixins.scss';

.borderedRow {
  th {
    border-bottom: 1px solid $hawkes-blue;
    padding-bottom: 20px;
  }

  td {
    padding: 20px 0;
    border-bottom: 1px solid $hawkes-blue;
  }
}

@import 'src/styles/colorVariables.scss';
@import 'src/styles/mixins.scss';

.topSourcesSection {
  max-width: 1170px;
  margin: 16px auto;
  border-radius: 24px;
  padding: 24px;
  background-color: $white;
}

.chartWrapper {
  display: flex;
  padding-top: 40px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0 0;
}

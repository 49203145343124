@import 'src/styles/colorVariables.scss';

.popupContainer {
  margin-top: 12px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  padding: 18px;
  display: flex;
  width: 450px;
  flex-shrink: 0;
  border-radius: 4px 14px 14px 14px;
  border: 1px solid #eef1fe;
  background: #fff;
  box-shadow: 4px 4px 19px 0px rgba(0, 0, 0, 0.05);
  flex-direction: column;
}

.popupTitle {
  color: #25262d;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
}

.popupDescription {
  color: var(--Gray, #86949d);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.12px;
  margin-top: 6px;
}

.popupDescription span {
  color: var(--Blue, #8080ff);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.12px;
}

.separatorLine {
  width: 100%;
  height: 1px;
  background: #eef1fe;
  margin: 18px 0;
}

.inputLabelContainer {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.inputLabel {
  color: var(--shark, #25262d);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.14px;
}

.infoBox {
  margin-top: 16px;
  display: flex;
  gap: 8px;
  color: var(--Text-sec, #86949d);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.14px;
}

.infoIcon {
  margin-top: 3px;
  color: #8080ff;
}

.formatString {
  color: #8080ff;
}

.listItems {
  overflow: auto;
  max-height: 305px;
  display: block;
}

.listItems::-webkit-scrollbar {
  width: 2px;
}

.listItems::-webkit-scrollbar-track {
  background: #eef1fe;
  border-radius: 26px;
}

.listItems::-webkit-scrollbar-thumb {
  height: 40px;
  background-color: #c8caff;
  border-radius: 26px;
  margin: 5px;
}

@import 'src/styles/colorVariables.scss';

.editCampaignBody {
  margin: 0 auto;
  min-height: 100vh;
  padding: 8px;
  padding-bottom: 100px;
  width: 1169px;
}

.body {
  display: flex;
  max-width: 100%;
}

.relevancy {
  min-width: 400px;
  padding: 36px 0 0 40px;
  flex: 1;
}

.formSection {
  margin-bottom: 24px;
  position: relative;
  width: 100%;
}

.sectionLabel {
  margin-bottom: 16px;
  padding: 0;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: $shark;
}

.hidden {
  display: none;
}

.suggestionWrapper {
  padding-top: 8px;
}

.suggestionLabel {
  font-size: 14px;
  font-weight: 500;
}

.suggestionValue {
  font-weight: 400;
  font-size: 14px;
  color: $malibu;
}

.editCampaignForm {
  max-width: 753px;
}

.positionRelative {
  position: relative;
}

.editCampaignTargets {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 0px !important;
  max-height: fit-content;
}

.tagsWrapper {
  position: relative;
  padding-bottom: 24px;
}

.timezoneSection {
  z-index: 10;
}

.actionsWrapper {
  position: fixed;
  background-color: $hawkes-blue;
  bottom: 0;
  right: 0;
  height: 72px;
  padding-left: 80px;
  width: 100%;
  z-index: 99;
  align-items: center;
}

.actionsWrapperInner {
  width: 1169px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 8px;
  margin: 0 auto;
}

.saveAsDraftButton {
  height: 48px;
  margin-right: 16px;
}

.addButton {
  height: 48px;
}

@import 'src/styles/colorVariables.scss';
@import 'src/styles/variables.scss';

.userDropDown {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.accountImage {
  align-items: center;
  background-color: $malibu;
  border: 1px solid $malibu;
  border-radius: 50%;
  color: $white;
  display: inline-flex;
  height: 32px;
  justify-content: center;
  width: 32px;
}

.dropDownButton {
  display: flex;
  align-items: center;
  margin-left: 16px;
}

.dropDownIcon {
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  transition: all 0.2s ease-in-out;
}

.dropDownIconReverse {
  transform: rotate(180deg);
}

.dropDownMenu {
  position: absolute;
  bottom: -8px;
  right: 0;
  transform: translateY(100%);
  width: 100%;
  background-color: $white;
  border-radius: 10px;
  padding: 8px 0;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: $z-index-top-bar-user-drop-down;
}

.dropDownItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  height: 32px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: $malibu-10;
  }

  &:last-child {
    padding-bottom: 0;
  }
}

@import 'src/styles/colorVariables.scss';
@import 'src/styles/variables.scss';

.filterModalContent {
  width: 100%;
  padding-top: 30px;
}

.sectionTitleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding-top: 25px;
  border-top: 1px solid $hawkes-blue;

  svg {
    margin-right: 5px;
  }
}

.sectionTitle {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.14px;
  color: $malibu;
  display: flex;
  align-items: center;
}

.amountFilterSection {
  margin-top: 14px;
  display: flex;
  align-items: center;
  height: 48px;
}

.typeWrapper {
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid $hawkes-blue;
  height: 100%;
  padding: 0 16px;
}

.radio {
  display: flex;
  align-items: center;
  height: 16px;

  & + & {
    margin-left: 12px;
    padding-left: 12px;
    border-left: 1px solid rgba(128, 128, 255, 0.2);
  }
}

.label {
  margin-left: 8px;
}

.mainLabel {
  padding: 0 16px;
}

.inputWrapper {
  height: 100%;
  flex: 1;
  border-radius: 10px;
  border: 1px solid $hawkes-blue;
  display: flex;
  align-items: center;

  input {
    flex: 1;
    padding: 0 16px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.14px;
    color: $shark;

    &::placeholder {
      color: $regent-gray;
    }
  }
}

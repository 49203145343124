@import 'src/styles/colorVariables.scss';

.smallRoundTag {
  align-items: center;
  border-radius: 50%;
  display: inline-flex;
  font-weight: 500;
  height: 24px;
  justify-content: center;
  line-height: 16px;
  min-width: 24px;
  font-size: 14px;
  background-color: $dark-titan-white;
  color: $regent-gray;
}

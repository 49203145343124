@import 'src/styles/colorVariables.scss';

.checkBoxWrapper {
  width: 16px;
  height: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid $malibu;

  svg {
    width: 12px;
    height: 12px;

    * {
      fill: $white;
    }
  }
}

.checked {
  background-color: $malibu;
}

.passive {
  background-color: $hawkes-blue;
  border: 1px solid $hawkes-blue;

  svg {
    * {
      fill: $malibu;
    }
  }
}

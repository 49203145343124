@import 'src/styles/colorVariables.scss';
@import 'src/styles/mixins.scss';

.hotLinksSection {
  background: $white;
  border-radius: 24px;
  display: inline-block;
  height: 436px;
  margin: 15px 0;
  padding: 24px 40px;
  width: 770px;
}

.cardsWrapper {
  display: flex;
  padding-top: 24px;
}

.card {
  border: 1px solid $hawkes-blue;
  border-radius: 18px;
  display: flex;
  flex-basis: 209px;
  flex-direction: column;
  flex-shrink: 0;
  height: 288px;
  width: 214px;
  margin-right: 25px;
  padding: 24px;
}

.externalLinkIcon {
  width: 15px;
  height: 15px;
  color: $malibu;
}

.cardHeader {
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;

  svg {
    width: 20px;
    height: 20px;
  }
}

.cardTitle {
  @include ellipsis;

  font-size: 16px;
  font-weight: 500;
  line-height: 18.75px;
  padding: 0 8px;
  min-width: 1;
}

.chartWrapper {
  display: flex;
  justify-content: center;
  padding: 24px 0 16px;
}

.nameWrapper {
  display: flex;
  align-items: center;
}

.name {
  @include ellipsis;

  font-size: 18px;
  font-weight: 500;
  line-height: 21.09px;
  flex: 1;
  min-width: 0;
  padding-right: 4px;
}

.date {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: $grey-100;
  padding-top: 4px;
}

.cardFooter {
  display: flex;
  font-size: 14px;
  font-weight: 500;
  justify-content: space-between;
  line-height: 16.41px;
  margin-top: auto;
}

.cardFooterWrapper {
  display: flex;
  align-items: center;
}

.cardFooterWrapperSpan {
  background-color: $malibu;
  border-radius: 50%;
  height: 8px;
  margin-right: 5px;
  width: 8px;
}

.neutral {
  background-color: $grey-200;
}

.negative {
  background-color: $burning-orange;
}

.loudspeakerIcon {
  width: 15px;
  height: 15px;
  margin-left: wpx;
}

.footer {
  height: 25px;
  margin: 0 auto;
  padding-top: 16px;
}

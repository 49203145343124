@import 'src/styles/colorVariables.scss';

.keywordsFilter {
  width: 100%;
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
}

.orderBy {
  min-width: 240px;
}

.keywordsFilterOpen {
  height: 100px;
  overflow: visible;
}

.inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0;
}

.right {
  width: 372px;
}

.sectionTitle {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  margin-bottom: 8px;
}

.selectWrapper {
  width: 372px;
  flex-shrink: 0;
}

@import 'src/styles/colorVariables.scss';

.filterWrapper {
  width: 100%;
}

.navMenuHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  width: 190px;
  border-bottom: 1px solid $hawkes-blue;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.41px;

  span {
    margin: 0 5px;
  }
}

.submenu {
  overflow: hidden;
  transition: all 250ms;
}

.hidden {
  max-height: 0;
}

.shown {
  max-height: 270px;
}

.filterActive {
  transform: rotate(-180deg);
}

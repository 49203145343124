@import 'src/styles/colorVariables.scss';

.positive {
  color: $malibu;
  background-color: transparent;
}

.negative {
  color: $burning-orange;
  background-color: transparent;
}

.other {
  background-color: transparent;
}

.bold {
  font-weight: bold;
}

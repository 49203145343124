@import 'src/styles/colorVariables.scss';

.mentions {
  width: 100%;
}

.title {
  color: $grey-100;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  padding-bottom: 4px;
}

.listItem {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 8px;
}

.label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.value {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 0.01em;
  margin: 0 2px 0 auto;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: $hawkes-blue;
  margin: 16px 0 8px;
}

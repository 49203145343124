@import 'src/styles/colorVariables.scss';
@import 'src/styles/mixins.scss';

.mentionsByMonth {
  width: 1170px;
  margin: 0 auto;
  border-radius: 24px;
  background: $white;
  height: 529px;
  padding: 24px 24px 44px;
  position: relative;
}

.chartContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  width: 100%;
}

.labels {
  bottom: 16px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 251px;
  left: 50%;
  transform: translateX(-50%);
}

@import 'src/styles/colorVariables.scss';
@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.cardFilterButton {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $malibu;
  font-family: 'Roboto';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.16px;
  cursor: pointer;
}

.filterIcon {
  width: 16px;
  height: 16px;
  margin-right: 4px;

  * {
    stroke: $malibu;
  }
}

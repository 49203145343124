@import 'src/styles/colorVariables.scss';
@import 'src/styles/mixins.scss';

.quickDetailsChart {
  height: 124px;
  margin: 0 auto;
  width: 124px;
  position: relative;
}

.active {
  height: 180px;
  width: 180px;
}

.chartInfo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.chartTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.chartTitleActive {
  font-weight: 500;
  font-size: 28px;
  line-height: 28px;
}

.chartSubTitle {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: $grey-100;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.01em;
  }
}

.chartSubTitleActive {
  span {
    font-size: 14px;
    line-height: 16px;
  }
}

.loudspeakerIcon {
  path {
    fill: $grey-100;
  }
}

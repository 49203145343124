@import 'src/styles/colorVariables.scss';

.themeDetail {
  background-color: $hawkes-blue;
  margin: 0 auto;
  padding: 8px;
  padding-bottom: 50px;
  width: 1250px;
}

.horizontalContainer {
  display: flex;
  justify-content: space-between;
  width: 1170px;
  margin: 0 auto;
}

@import 'src/styles/colorVariables.scss';

.react-tagsinput--focused {
  border-color: $periwinkle !important;
}

.addNewThemeCard {
  background-color: $white;
  border-radius: 24px;
  margin-bottom: 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  cursor: pointer;
}

.headerSpan {
  display: inline-flex;
  align-items: center;

  span {
    display: inline-flex;
    align-items: center;
  }
}

.iconContainerChariot {
  margin: 0 10px;
  cursor: pointer;

  img {
    transform: rotate(-90deg);
    height: 12.73px;
  }
}

.iconContainerChariotOpen {
  img {
    transform: rotate(-270deg);
  }
}

.headerCounts {
  font-size: 14px;
}

.bookmark {
  font-size: 14px;
  color: $malibu;
  display: none;
}

.open {
  display: inline-flex;
}

.displayNone {
  display: none;
}

.keywordContent {
  height: 0;
  padding: 0 18px;
  transition: all 0.1s linear;
  overflow: hidden;
  border-top: 1px solid $hawkes-blue;
}

.expanded {
  height: auto;
  padding: 18px;
}

.categoriesSection {
  margin-bottom: 18px;
  padding: 0;
}

.label {
  width: 100%;
  margin-bottom: 2px;
  font-family: Manrope-600;
  font-size: 14px;
  line-height: 22px;
  color: $shark;
  margin-bottom: 8px;
}

.categoriesList {
}

.categoriesTag {
  display: inline-block;
  padding-right: 8px;
  padding-top: 8px;
  cursor: pointer;
}

.addNewThemeAutofill {
  font-family: 'Roboto';
  height: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: $regent-gray;
  position: absolute;
  top: 0;
  left: 0;
  vertical-align: middle;
}

.addNewThemeInput {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.01em;
  position: relative;
  height: unset;
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  width: 100%;
  min-width: 160px;
}

.tagsInputWrapper {
  display: flex;
  align-items: center;
}

.suggestions {
  margin-top: 2px;
}

.suggestionsTitle {
  font-size: 14px;
  margin-right: 4px;
  font-weight: 500;
}

.suggestionsLabel {
  font-size: 14px;
  color: $malibu;
  cursor: pointer;
  font-weight: 400;
}

.positiveKeys {
  padding: 0;
  margin-bottom: 18px;
}

.negativeKeys {
  margin-bottom: 18px;
}

.copyIcon {
  margin-left: 8px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.deleteButton {
  margin-left: 16px;
}

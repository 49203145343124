.input {
  width: 569px;
  height: 82px;
  border-radius: 10px;
  border: 1px solid var(--Blue, #8080ff);
  background: #fff;
  box-shadow: 0px 0px 0px 3px rgba(128, 128, 255, 0.15);
  color: #8080ff;
  font-size: 14px;
  font-family: 'Roboto';
  outline: none;
  margin-top: 14px;
  padding: 0 17px 17px 17px;
}

.input::placeholder {
  color: #86949d;
}

@import 'src/styles/colorVariables.scss';
@import 'src/styles/mixins.scss';

.liveNotificationItem {
  align-items: center;
  margin-bottom: 24px;
  position: relative;
  padding-left: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 14px;
  cursor: pointer;
  background: transparent;
  transition: all 0.3s ease;

  &.checked,
  &:hover:not(.noHoverCheckbox) {
    padding-left: 40px;
    background: #eef1fe;
    border-radius: 12px 0 0 12px;
  }

  &.checked .checkboxWrapper,
  &:hover:not(.noHoverCheckbox) .checkboxWrapper {
    opacity: 1;
    visibility: visible;
  }
}

.checkboxWrapper {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.3s ease,
    visibility 0.3s ease;
}

.checkbox {
  appearance: none;
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  cursor: pointer;
  border: 1.5px solid #8080ff;
  border-radius: 4px;
  position: relative;
}

.checkbox:checked {
  background-color: #8080ff;
  border-color: #8080ff;
}

.checkbox:checked::after {
  content: '';
  position: absolute;
  top: 1px;
  left: 4px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.titleWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.title {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
}

.icon {
  svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
}

.externalIcon {
  width: 18px;
  height: 18px;
  margin-left: 8px;
}

.campaignTitle {
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  margin-bottom: 5px;
  color: $grey-100;
  letter-spacing: 0.01em;
  display: flex;
}

.text {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
}

.positive {
  color: $malibu;
  background-color: transparent;
}

.negative {
  color: $burning-orange;
  background-color: transparent;
}

.other {
  background-color: transparent;
}

.bold {
  font-weight: bold;
}

.languageLabel {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  background-color: $malibu;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  border-radius: 10px;
  padding: 0 8px;
  margin-left: auto;
}

.translations {
  display: flex;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  padding-top: 4px;
}

.translate {
  color: $malibu;
  cursor: pointer;
}

.score {
  align-items: center;
  background-color: #003580;
  border-radius: 5px;
  color: white;
  display: flex;
  font-size: 12px;
  height: 20px;
  justify-content: center;
  margin-left: 32px;
  width: 32px;
}

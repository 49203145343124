@import 'src/styles/colorVariables.scss';
@import 'src/styles/variables.scss';

.liveNotifications {
  width: 1170px !important;
  max-width: 1170px;
  margin: 15px auto;
  border-radius: 24px;
  background-color: $white;
  color: $shark;
  height: 540px;
  padding: 24px;
}

.innerContainer {
  display: flex;
  justify-content: space-between;
}

.notificationsContainer {
  padding: 0 20px;
  width: 100%;
  height: $live-notification-container-height;
}

.notificationsContainerTitleWrapper {
  display: flex;
  gap: 14px;
  align-items: center;
  margin-bottom: 25px;
  margin-top: 20px;
  padding-right: 13px;
}

.notificationsContainerTitleActive {
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.18px;
  cursor: pointer;
}

.notificationsContainerTitle {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.16px;
  color: #929296;
  cursor: pointer;
}

.notificationsContainerTitle,
.notificationsContainerTitleActive {
  transition:
    color 0.3s ease,
    font-size 0.3s ease;
}

.scrollbarContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-bottom: 25px;
  padding-right: 13px;

  &::-webkit-scrollbar-track {
    background: $hawkes-blue;
    border-radius: 26px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c8caff;
    border-radius: 26px;
  }

  &::-webkit-scrollbar {
    width: 3px;
  }
}

.positive {
  color: $malibu;
  background-color: transparent;
}

.negative {
  color: $burning-orange;
  background-color: transparent;
}

.other {
  background-color: transparent;
}

@import 'src/styles/colorVariables.scss';
@import 'src/styles/variables.scss';

.options {
  width: 100%;
  overflow-y: auto;
  flex: 1;
}

.option {
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;

  &:hover {
    background-color: rgba(128, 128, 255, 0.1);
  }
}

.label {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
  color: $black;
  margin-left: 8px;
}

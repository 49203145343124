@import 'src/styles/colorVariables.scss';
@import 'src/styles/mixins.scss';

.totalMentionPercentage {
  display: inline-block;
}

.details {
  display: flex;
  align-items: center;
  color: $shark;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.09px;
  margin-bottom: 8px;

  span {
    padding-right: 4px;
  }
}

.label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: $grey-100;
}

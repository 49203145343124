// sizes
$live-notification-container-height: 409px;
$sentiment-analysis-container-height: 400px;
$admin-threads-container-height: 540px;

// z-index
$z-index-top-bar-user-drop-down: 100;
$z-index-modal: 1000;

:export {
  liveNotificationContainerHeight: $live-notification-container-height;
  sentimentAnalysisContainerHeight: $sentiment-analysis-container-height;
  adminThreadsContainerHeight: $admin-threads-container-height;
}

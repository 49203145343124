@import 'src/styles/colorVariables.scss';

.campaignsFilter {
  height: 0;
  overflow: hidden;
  transition: height 0.4s ease-in-out;
  -moz-transition: height 0.4s ease-in-out;
  -webkit-transition: height 0.4s ease-in-out;
  &.open {
    height: 100px;
    overflow: unset;
  }

  .content {
    padding-top: 24px;
    display: flex;
    justify-content: space-between;

    .column {
      width: 570px;

      .title {
        margin-bottom: 14px;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.41px;
      }

      .dropdown {
        width: 100%;

        select,
        select:active,
        select:focus,
        select > * {
          box-shadow: none !important;
          outline: none !important;
          border: none !important;
        }

        select {
          height: 48px;
          border-radius: 10px;
          background-color: $white !important;
          cursor: pointer;

          option {
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;

            &:hover,
            &:checked {
              background-color: $malibu-10 !important;
            }
          }
        }
      }
    }
  }
}

@import 'src/styles/colorVariables.scss';
@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.sentimentAnalysesSection {
  justify-content: space-between;
  max-width: 1170px;
  margin: 0 auto;
  border-radius: 24px;
  height: 540px;
  padding: 24px;
  background: $white;
}

.chartWrapper {
  display: flex;
  height: 100%;
  position: relative;
  width: 100%;
}

@import 'src/styles/colorVariables.scss';
@import 'src/styles/variables.scss';

.filterModalContent {
  width: 100%;
  padding-top: 30px;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: #eef1fe;
  margin-top: 24px;
}

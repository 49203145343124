@import 'src/styles/colorVariables.scss';

.autofillInput {
  display: inline-flex;
  position: relative;
  flex: 1;
}

.autofill {
  height: 100%;
  color: $regent-gray;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: transparent;
  pointer-events: none;
  display: flex;
  align-items: center;
}

.input {
  z-index: 2;
  position: relative;
  border: none;
}

.fullWidth {
  width: 100%;
}

@import 'src/styles/colorVariables.scss';

.filterWrapper {
  width: 100%;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;

  .navMenuHeader {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    border-bottom: 1px solid $hawkes-blue;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.41px;

    span {
      margin: 0 5px;
    }

    .icon {
      width: 9.55px;
      color: #09121f;
      transition: transform 0.25s ease-in-out;

      &.filterActive {
        transform: rotate(-180deg);
      }
    }
  }

  .submenu {
    overflow: hidden;
    transition: max-height 0.25s ease-in-out;

    &.hidden {
      max-height: 0;
    }

    &.shown {
      max-height: 270px;
    }
  }
}

@import 'src/styles/colorVariables.scss';

.horizontal {
  padding-bottom: 22px;
  display: flex;
  overflow-x: auto;
  width: 100%;

  &::-webkit-scrollbar-track {
    background: $white;
    border-bottom: 2px solid $hawkes-blue;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: $white;
    border-bottom: 2px solid $periwinkle;
    height: 4px;
  }

  &::-webkit-scrollbar {
    height: 4px;
    cursor: pointer;
  }
}

.vertical {
  height: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    background: $white;
    border-right: 2px solid $hawkes-blue;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: $white;
    border-right: 2px solid $periwinkle;
    width: 4px;
  }

  &::-webkit-scrollbar {
    width: 4px;
    cursor: pointer;
  }
}

@import 'src/styles/colorVariables.scss';
@import 'src/styles/mixins.scss';

.mentionItem {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.14px;
  color: $regent-gray;

  svg {
    path {
      fill: $regent-gray;
    }
  }
}

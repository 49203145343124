@import 'src/styles/colorVariables.scss';

$timing: 0.1s;

.datepicker {
  height: 38px;
  position: relative;
  display: inline-block;
  border-radius: 10px;
  font-family: Roboto;
}

.calendarTooltip {
  position: absolute;
  top: 8px;
  right: -10px;
  transform: translateX(100%);
}

.calendarWrapper {
  display: flex;
  align-items: flex-start;
  background-color: $white;
  z-index: 100;
  position: relative;
}

.dropdownEl {
  min-height: 38px;
  max-height: 38px;
  position: relative;
  display: inline-block;
  z-index: 99;
  overflow: hidden;
  cursor: pointer;
  white-space: nowrap;
  color: #444;
  outline: none;
  border: 0.06em solid transparent;
  border-radius: 1em;
  background-color: $white;
  transition: $timing all ease-in-out;
  padding: 0px;
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;

  input {
    width: 1px;
    height: 1px;
    display: inline-block;
    position: absolute;
    opacity: 0.01;
  }

  input:focus + label {
    background-color: $white;
  }

  label {
    height: 36px;
    line-height: 2em;
    padding-left: 15px;
    padding-right: 3em;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    width: 100%;

    &:nth-child(2) {
      margin-top: 2em;
      border-top: 1px solid $titan-white;
    }
  }

  input:checked + label {
    border-top: none;
    position: absolute;
    top: 0;

    &:nth-child(2) {
      margin-top: 0;
      position: relative;
    }
  }

  &::after {
    display: inline-block;
    vertical-align: middle;
    border-style: solid;
    border-width: 2px 2px 0 0;
    content: '';
    position: absolute;
    right: 15px;
    top: 12px;
    height: 5px;
    width: 5px;
    transform: rotate(135deg);
    transition: 0.4s all ease-in-out;
  }
}

.expanded {
  background: #fff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 3px 3px 5px 0px;
  max-height: 296px;

  label {
    border-top: 1px solid $titan-white;

    &:hover {
      background-color: rgba(128, 128, 255, 0.1);
    }
  }

  input:checke.categories-checkboxesd + label {
    color: $shark;
  }
}

.labelCheck {
  display: inline-flex;
}

.flexDirectionColumn {
  flex-direction: column;
}

.periodLabel {
  width: 100%;
  font-size: 13px;
  height: auto;
}

.customPeriod {
  font-size: 11px;
  height: 13px;
}

.periodDates {
  height: 12px;
}

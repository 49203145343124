@import 'src/styles/colorVariables.scss';
@import 'src/styles/mixins.scss';

.quickDetailsSection {
  background: $white;
  border-radius: 24px;
  min-height: 436px;
  margin: 15px 0;
  padding: 24px;
  width: 770px;
}

.quickDetailsSectionInner {
  color: $shark;
  margin: 32px auto 0 auto;
}

.scrollBarContainer {
  height: 100%;
  display: flex;
}

.cardWrapper {
  display: flex;
  cursor: pointer;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.cardName {
  @include ellipsis();

  color: $shark;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.09px;
  padding-top: 16px;
  margin: auto 0 8px;
}

.bottomDetails {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $shark;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.09px;
  margin-bottom: 8px;
}

.bottomLabel {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: $grey-100;
}

.footer {
  height: 25px;
  margin: 0 auto;
  padding-top: 16px;
}

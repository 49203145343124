@import 'src/styles/colorVariables.scss';
@import 'src/styles/mixins.scss';

.topCampaigns {
  width: 100%;
}

.title {
  color: $grey-100;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  padding-bottom: 4px;
}

.listItem {
  @include ellipsis();

  width: 100%;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding-top: 8px;
  min-width: 0;
}

@import 'src/styles/colorVariables.scss';
@import 'src/styles/variables.scss';

.filterModalContentWrapper {
  min-width: 616px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-family: 'Roboto';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.24px;
}

.closeButton {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.resetButton {
  margin-right: auto;
  display: flex;
  align-items: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.16px;
  color: $malibu;
}

.resetIcon {
  width: 16px;
  height: 16px;
  margin-right: 6px;

  * {
    stroke: $malibu;
  }
}

.cancel {
  height: 40px;
  padding: 0 16px;
  font-weight: 500;
  color: $shark;
  margin-right: 16px;
  transition: all 0.3s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
}

@import 'src/styles/variables.scss';
@import 'src/styles/colorVariables.scss';
@import 'src/styles/mixins.scss';

.horizontalContainer {
  max-width: 1200px;
  height: $admin-threads-container-height;
  margin: 15px auto;
  display: flex;
  flex-direction: column;
}

.contentWrapper {
  width: 100%;
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
}

.navMenu {
  width: 100%;
  margin: 40px 15px 15px 0;
  display: flex;
  gap: 20px;
}

.submenuInner {
  width: 100%;
  max-height: 230px;
  overflow-y: auto;
}

.submenuItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 14px;
  cursor: pointer;
}

.submenuLabel {
  @include ellipsis;

  padding-left: 8px;
  min-width: 0;
  flex: 1;
}

.datePickerWrapper {
  width: 100%;
  height: 44px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.41px;
  display: flex;
  align-items: center;
  position: relative;

  .datePickerLabel {
    position: absolute;
  }

  .datePickerIcon {
    width: 18px;
    height: 18px;
    position: absolute;
    right: 6px;
  }

  .datePicker {
    width: 100%;
    height: 44px;
    padding-left: 70px;
    font-weight: 500;
    cursor: pointer;
  }
}

.scrollbarContainer {
  width: 100%;
  height: 100%;
  padding-bottom: 25px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar-track {
    background: $hawkes-blue;
    border-radius: 26px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c8caff;
    border-radius: 26px;
  }

  &::-webkit-scrollbar {
    width: 3px;
  }
}

.info {
  height: 100%;
  display: grid;
  place-items: center;
}

.table {
  position: relative;
  color: $shark;

  thead {
    background: $dark-titan-white;
    position: sticky;
    top: 0;

    tr {
      th {
        padding: 10px;
        font-size: 15px;
        line-height: 18px;
        font-weight: bold;
      }
    }
  }

  tbody {
    tr {
      background-color: $ghost-white;

      &:nth-child(2n) {
        background-color: $hawkes-blue;
      }

      td {
        padding: 10px;
        font-size: 14px;
        line-height: 16px;

        &:nth-child(1) {
          width: 120px;
          word-break: break-all;
        }

        &:nth-child(2) {
          width: 85px;
          word-break: break-all;
        }

        .dateWrapper {
          width: 88px;
        }

        .sourceWrapper {
          width: 137px;
          font-weight: 500;
          display: flex;
          align-items: center;

          .icon {
            svg {
              width: 16px;
              height: 16px;
              margin-right: 8px;
            }
          }

          .externalIconWrapper {
            margin-left: auto;

            .externalIcon {
              width: 18px;
              height: 18px;
            }
          }
        }
      }
    }
  }
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
  font-family: 'Inter-500';
  src: url('../assets/fonts/Inter/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: Manrope-600;
  src: url('../assets/fonts/Manrope/Manrope-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Manrope-700';
  src: url('../assets/fonts/Manrope/Manrope-Bold.ttf') format('truetype');
  font-weight: 700;
}

@import 'src/styles/colorVariables.scss';
@import 'src/styles/mixins.scss';

.card {
  width: 164px;
  margin-right: 64px;
}

.chart {
  width: 100%;
  height: 164px;
  position: relative;
}

.name {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: $shark;
  padding: 24px 0 16px;
  text-align: center;
}

.chartInfo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.chartTitle {
  color: $shark;
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
}

.chartSubTitle {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: $grey-100;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    padding-left: 2px;
  }
}

.loudspeakerIcon {
  path {
    fill: $grey-100;
  }
}

$black: #000000;
$white: #ffffff;
$shark: #25262d;
$regent-gray: #86949d;
$malibu: #8080ff;
$burning-orange: #ff6833;
$melrose: #9999ff;
$apricot: #eb9373;
$seashell: #fff0eb;
$dark-blue: #0a12d6;
$periwinkle: #c8caff;
$pink-lace: #ffc0f8;
$red-orange: #f73434;
$fog: #d9d9ff;
$dark-titan-white: #e3e4ff;
$hawkes-blue: #eef1fe;
$ghost-white: #f5f7fe;
$titan-white: #f3f3ff;
$alto: #d7d7d7;
$iron: #d3d4d5;
$manatee: #8383a5;
$grey-100: #929296;
$grey-200: #c7ced1;
$hptt-sp-1: #d3d5ff;
$hptt-sp-2: #0a12d6;
$hptt-sp-3: #edf0f4;
$hptt-sp-4: #5a8de9;
$malibu-10: rgba(128, 128, 255, 0.1);

:export {
  malibu: $malibu;
  burningOrange: $burning-orange;
  melrose: $melrose;
  apricot: $apricot;
  grey200: $grey-200;
  grey100: $grey-100;
  darkBlue: $dark-blue;
  iron: $iron;
  hpttSp1: $hptt-sp-1;
  hpttSp3: $hptt-sp-3;
  hpttSp4: $hptt-sp-4;
  white: $white;
  black: $black;
  periwinkle: $periwinkle;
  pinkLace: $pink-lace;
  redOrange: $red-orange;
  shark: $shark;
  regentGray: $regent-gray;
  hawkesBlue: $hawkes-blue;
  alto: $alto;
  fog: $fog;
  darkTitanWhite: $dark-titan-white;
  seashell: $seashell;
  ghostWhite: $ghost-white;
}

@import 'src/styles/colorVariables.scss';
@import 'src/styles/mixins.scss';

.container {
  background-color: $hawkes-blue;
  margin: 0 auto;
  padding: 8px;
  padding-bottom: 50px;
  width: 1250px;
}

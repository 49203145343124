@import 'src/styles/colorVariables.scss';

.addKeywordInputWrapper {
  width: 100%;
}

.labelWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.label {
  color: $shark;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
}

.keywordCount {
  color: $regent-gray;
  text-align: right;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
}

.addKeywordInputInner {
  position: relative;
  border-radius: 10px;
  height: 50px;
  overflow: hidden;
  background-color: $white;
}

.addKeywordInput {
  width: 100%;
  height: 48px;
  font-family: Manrope-600;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  letter-spacing: 0.01em;
  background-color: transparent;
  border: 1px solid $hawkes-blue;
  color: black;
  position: relative;
  padding-left: 13px;
  margin: 0;
}

.addKeywordAutoFill {
  width: 100%;
  height: 48px;
  font-family: Manrope-600;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  letter-spacing: 0.01em;
  padding-left: 13px;
  margin-top: 1px;
  display: flex;
  align-items: center;
  color: $regent-gray;
}

.button {
  position: absolute;
  color: $white !important;
  height: 39px;
  padding: 0 21px;
  right: 6px;
  top: calc((50px - 39px) / 2);
  z-index: 2;
}

.uploadWrapper {
  display: flex;
  align-items: center;
  gap: 6px;
  position: absolute;
  top: calc((50px - 17.5px) / 2);
  z-index: 2;
  right: 15px;
  cursor: pointer;
}

.uploadText {
  color: #8080ff;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.14px;
}

.calendarSectionNavigation {
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: space-between;
}

.arrow {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
}

.arrowLeft {
  transform: rotate(180deg);
}

@import 'src/styles/colorVariables.scss';
@import 'src/styles/mixins.scss';

.topSources {
  width: 100%;
}

.title {
  color: $grey-100;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  padding-bottom: 4px;
}

.listItem {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 8px;

  svg {
    width: 18px;
    height: 18px;
  }
}

.label {
  @include ellipsis();

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  min-width: 0;
  margin-left: 8px;
}

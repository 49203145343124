@import 'src/styles/colorVariables.scss';
@import 'src/styles/mixins.scss';

.sourcesList {
  width: 125px;
}

.title {
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.01em;
  padding-bottom: 8px;
}

.item {
  color: $grey-100;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  padding-top: 8px;
  display: flex;
  align-items: center;

  svg {
    height: 15px;
    margin-right: 8px;
    width: 15px;

    path {
      fill: $grey-100;
    }
  }
}

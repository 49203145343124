.btnWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  padding: 10px 16px;
  border-radius: 10px;
  background: #8080ff;
  box-shadow:
    0px 360px 101px 0px rgba(0, 0, 153, 0),
    0px 230px 92px 0px rgba(0, 0, 153, 0.01),
    0px 130px 78px 0px rgba(0, 0, 153, 0.03),
    0px 58px 58px 0px rgba(0, 0, 153, 0.05),
    0px 14px 32px 0px rgba(0, 0, 153, 0.06);
  cursor: pointer;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 999;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.text {
  color: #fff;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.16px;
}

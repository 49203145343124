.keywordCardItem {
  cursor: pointer;
  height: 263px;
  width: 570px;
  position: relative;
}

.cardInner {
  display: flex;
  margin-bottom: 10px;
  font-size: 1.125rem;
}

.titleWrapper {
  display: flex;
  align-items: center;
}

.title {
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
}

.subtitleWrapper {
  display: flex;
  align-items: center;
}

.subtitle {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.ball {
  font-size: 10px;
  margin: auto 8px;
}

.activeMentions {
  display: flex;
}

.info {
  display: flex;
  justify-content: space-between;
}

.infoCol {
  height: 130px;
  padding: 32px 0 0;
}

.infoTitle {
  font-size: 12px;
  line-height: 14px;
}

.infoLine {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.themecardKey {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.themecardValue {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin-left: 8px;
}

.detailsWrapper {
  display: flex;
}

.targetsWrapper {
  display: flex;
  padding: 20px 0 0 24px;
}

.target {
  & + & {
    margin-left: 8px;
  }
}

.targetLeft {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin-right: 8px;
}

.targetLeftIcon {
  margin-right: 2px;
  height: 15px;
  width: 15px;
}

.targetRight {
  font-size: 12px;
  line-height: 14px;
}

.chartWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 140px;
  width: 140px;
}

.footer {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
}

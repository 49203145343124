@import 'src/styles/variables.scss';
@import 'src/styles/colorVariables.scss';
@import 'src/styles/mixins.scss';

.stickyHead {
  position: sticky;
  background-color: $white;
  top: 0px;
  margin: 0 0 0 0;
}

@import 'src/styles/colorVariables.scss';

.positivityChartSection {
  background: $periwinkle;
  border-radius: 24px;
  height: 436px;
  margin: 15px 25px 15px 0;
  padding: 24px;
  width: 370px;
}

.tooltip {
  margin-left: 8px;
  display: flex;
  align-items: center;
}

.chart {
  height: 137px;
  width: 274px;
  margin: 88px auto 56px;
  display: inline-flex;
  justify-content: center;
  position: relative;
}

.chartInfo {
  position: absolute;
  bottom: -24px;
  left: 50%;
  transform: translateX(-50%);
}

.chartTitle {
  color: $shark;
  font-size: 32px;
  font-weight: 500;
  line-height: 42px;
}

.chartSubTitle {
  color: $shark;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  padding-top: 8px;
}

.textWrapperLabel {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: $shark;
  opacity: 0.5;
}

.text {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 6px;

  span {
    color: $shark;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: right;
    letter-spacing: 0.01em;
    padding-right: 4px;
  }
}

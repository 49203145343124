@import 'src/styles/variables.scss';
@import 'src/styles/colorVariables.scss';
@import 'src/styles/mixins.scss';

.nameCell {
  color: $black;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.16px;
  padding-right: 60px;
}

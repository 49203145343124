@import 'src/styles/colorVariables.scss';

.card {
  width: 370px !important;
  height: 112px;
  margin: 0;
  padding: 20px !important;
  border-radius: 18px;
  background-color: $white;
  display: flex;
  align-items: center;

  .leading {
    width: 48px;
    height: 48px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 28px;
      height: 28px;
    }
  }

  .infoColumn {
    width: 140px;
    height: 100%;
    margin: 0 16px 0 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    .title {
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      color: $regent-gray;
    }

    .middle {
      display: flex;
      align-items: center;
      width: 140px;

      .blockText {
        font-size: 24px;
        font-weight: 700;
        color: $shark;

        &.fullWidthBlockText {
          width: 100%;
        }
      }

      .percent {
        display: inline-flex;

        &.positive {
          color: $malibu;
        }

        &.negative {
          color: $burning-orange;
        }

        .arrow {
          margin: 0 5px 0 11.5px;
        }
      }
    }

    .footNote {
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      color: $regent-gray;

      .percent {
        display: inline-flex;

        &.positive {
          color: $malibu;
        }

        &.negative {
          color: $burning-orange;
        }

        .arrow {
          margin: 0 3px 0 8px;
        }
      }
    }
  }

  .trailing {
    max-width: 106px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .defaultChartWrapper {
      margin: 0;
    }

    .totalChartWrapper {
      margin-right: 12px;
    }

    .topLocationChartWrapper {
      height: 72px;
    }

    .topSourceChartWrapper {
      position: relative;
      margin-right: 13px;

      .label {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 14px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

@import 'src/styles/colorVariables.scss';

.settings {
  margin: 0 auto;
  max-width: 1169px;
  min-height: 100vh;
  padding: 8px 8px 50px;
}

.backButton {
  position: relative;
  z-index: 1;
}

.filtersWrapper {
  border-bottom: 2px solid rgba($periwinkle, 0.1);
  height: 45px;
  margin-bottom: 30px;
  margin-top: 39px;
}

.filetrs {
  height: 100%;
  display: flex;
}

.pills {
  width: 550px;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}

.pill {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  border-radius: 4px;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: $shark;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;

  &:hover {
    background-color: rgba($periwinkle, 0.1);
  }
}

.pillActive {
  background-color: $malibu;
  color: $white;
}

.socialWrapper {
  margin-top: 30px;
}

.row {
  display: flex;
  align-items: center;
  height: 48px;
}

.title {
  width: 22.3%;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: $shark;
}

@import 'src/styles/variables.scss';
@import 'src/styles/colorVariables.scss';
@import 'src/styles/mixins.scss';

.mentionsCell {
  color: $regent-gray;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.16px;
  display: flex;
  align-items: center;

  svg {
    margin-left: 4px;

    * {
      fill: $regent-gray;
    }
  }
}

.percentage {
  color: $shark;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.16px;
}

.separator {
  margin: 0 8px;
  background-color: rgba(128, 128, 255, 0.2);
  width: 1px;
  height: 16px;
}

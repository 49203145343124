@import 'src/styles/colorVariables.scss';
@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.sentimentAnalysesNotifications {
  width: 100%;
}

.filterButton {
  margin-left: auto;
}

.emptyState {
  display: flex;

  align-items: center;
  justify-content: center;
  height: 100%;
}

.chartInner {
  width: 100%;
  height: $sentiment-analysis-container-height;
}

.filtersWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 25px;
  padding-right: 16px;
  color: rgba($shark, 0.5);
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  position: relative;
}

.filterItem {
  display: flex;
  align-items: center;
  margin-right: 16px;
  cursor: pointer;
}

.filterItemActive {
  color: rgba($shark, 1);
  font-weight: 500;
}

.dropdownWrapper {
  position: relative;
}

.relevanceWrapper {
  background: white;
  padding: 6px 8px 6px 12px;
  border-radius: 100px;
  border: 1px solid #eef1fe;
  color: black;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.16px;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.relevanceWrapper.active {
  color: #fff;
  background: #8080ff;
}

.dropdown {
  width: 143px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid #eef1fe;
  background: #fff;
  box-shadow: 4px 4px 19px 0px rgba(0, 0, 0, 0.05);
  position: absolute;
  top: 40px;
  overflow: hidden;
  z-index: 9999;
}

.dropdownList {
  width: 100%;
  padding: 12px 12px 12px 16px;
  background: #fff;
  color: #25262d;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.16px;
  cursor: pointer;
  border-bottom: 1px solid #eef1fe;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.arrowSvg {
  width: 16px;
  height: 16px;
  transition:
    transform 0.3s ease,
    fill 0.3s ease;
}

.arrowSvg path {
  fill: #8080ff;
}

.arrowSvg.active {
  transform: rotate(180deg);
}

.arrowSvg.active path {
  fill: white;
}

.line {
  width: 1px;
  height: 16px;
  background: #eef1fe;
  margin: 0 14px;
}

.selectionWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
  width: 100%;
}

.icons {
  display: flex;
  align-items: center;
}

.checkedIcon {
  width: 18px;
  height: 18px;
}

.iconWrapperDeselect,
.iconWrapperCheck,
.iconWrapperMoveTo,
.iconWrapperDelete,
.iconWrapperReport,
.deselectIcon,
.iconWrapperSentiment {
  cursor: pointer;
  position: relative;
}

.iconWrapperCheck,
.iconWrapperMoveTo,
.iconWrapperDelete,
.iconWrapperReport,
.iconWrapperSentiment {
  margin-right: 18px;
}

.deselectIcon {
  width: 18px;
  height: 18px;
}

.infoMessageSelect,
.infoMessageDeselect,
.infoMessageMoveTo,
.infoMessageReport,
.infoMessageDelete,
.infoMessageSentiment {
  width: fit-content;
  white-space: nowrap;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background: var(--Blue, #8080ff);
  border: 1px solid rgba(255, 255, 255, 0.25);
  position: absolute;
  top: 25px;

  z-index: 9999;
  color: #fff;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.14px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
}

.infoMessageDeselect,
.infoMessageMoveTo,
.infoMessageReport,
.infoMessageSentiment,
.infoMessageDelete {
  left: 50%;
  transform: translateX(-50%);
}

.iconWrapperCheck:hover .infoMessageSelect,
.iconWrapperDeselect:hover .infoMessageDeselect,
.iconWrapperMoveTo:hover .infoMessageMoveTo,
.iconWrapperReport:hover .infoMessageReport,
.iconWrapperDelete:hover .infoMessageDelete,
.iconWrapperSentiment:hover .infoMessageSentiment {
  visibility: visible;
  opacity: 1;
}

.changeSentimentModal {
  width: fit-content;
  white-space: nowrap;
  padding: 18px 14px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background: #ffffff;
  border: 1px solid #e3e4ff;
  position: absolute;
  top: 25px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  color: #fff;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.14px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
  width: 185px;
  color: black;
  cursor: default;
}

.changeSentimentModal[data-set='true'] {
  visibility: visible;
  opacity: 1;
}
.iconWrapperSentiment[data-set='true'] svg path {
  fill: #8080ff;
}

.changeSentimentModalItem {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.sentimentItem {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 14px;
}

.sentimentItemColor {
  width: 8px;
  height: 8px;
  border-radius: 100%;
}

.sentimentItemColor[data-set='Positive'] {
  background: #8080ff;
}
.sentimentItemColor[data-set='Negative'] {
  background: #ff6833;
}
.sentimentItemColor[data-set='Neutral'] {
  background: #c7ced1;
}

.inactiveSentimentItem {
  opacity: 0.5;
}

.changeSentimentModal > *:not(:last-child)::after {
  content: '';
  width: 100%;
  height: 1px;
  background-color: #8080ff1a;
  display: block;
  margin: 16px 0;
}

@import 'src/styles/colorVariables.scss';

.signupWrapper {
  margin-left: 20px;
  margin-right: 20px;
}

.signinContainer {
  height: 100vh;
  padding: 0 !important;
  position: relative;
  top: -70px;
  display: flex;
  justify-content: center;
  align-items: center;

  body &__submit {
    height: 48px;
    color: white !important;
    font-size: 12px;
    font-family: Manrope-600;
    background-color: $malibu;

    &:focus {
      background-color: $malibu;
      color: $white;
    }
  }
}

.card {
  width: 490px;
}

.header {
  width: 100%;
  margin-bottom: 32px;
}

.title {
  font-size: 30px;
  font-family: Manrope-700;
  line-height: 40.98px;
  color: $shark;
}

.subtitle {
  font-size: 14px;
  font-family: Roboto;
  color: $manatee;
  font-weight: 400;
  line-height: 24px;
  width: max-content;
}

.subtitleLink {
  color: $malibu;
  text-decoration: none;
  font-weight: 500;
  margin-left: 8px;
}

.nameWrapper {
  display: flex;
}

.inputContainer {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}

.inputContainerHalf {
  width: 50%;
  padding-right: 8px;

  & + & {
    padding: 0 0 0 8px;
  }
}

.label {
  font-family: Manrope-600;
  font-size: 14px;
  line-height: 22px;
  color: $shark;
  margin-bottom: 8px;
}

.input {
  color: $manatee;
  background-color: white;
  height: 48px;
  border: 1px solid $hawkes-blue;
  padding: 13px;
  font-family: Manrope-600;
  font-size: 12px;
  line-height: 22px;
  font-weight: 600;
  color: $manatee;
  border-radius: 8px;
  width: 100%;
}

.passwordWrapper {
  position: relative;
}

.passwordButton {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.forgotPasswordLink {
  display: block;
  color: $malibu;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-decoration: none;
}

.createButton {
  width: 100%;
  margin-top: 16px;
}

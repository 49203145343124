@import 'src/styles/colorVariables.scss';

.sidebarContainer {
  width: 205px;
  height: 100%;
  padding: 15px 0 15px 30px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 100;

  .sidebarHeader {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    margin-bottom: 52px;

    .sidebarLogo {
      height: 32px;
      overflow: hidden;
    }
  }

  .sidebarMenu {
    flex-grow: 1;
    gap: 13px;
    color: $regent-gray;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;

    .sidebarMenuItem {
      height: 30px;
      display: flex;
      align-items: center;
      overflow: hidden;
      white-space: nowrap;
      text-decoration: none;
      cursor: pointer;
      color: $regent-gray;
      transition: color 0.2s ease-in-out;

      &:hover,
      &:focus-visible,
      &.active {
        color: $shark;
      }

      &.bottom {
        margin-top: auto;
      }

      .sidebarMenuItemIconWrapper {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-left: 3px;
        margin-right: 27px;
        text-align: center;
        color: inherit;

        .sidebarMenuItemIcon {
          height: 20px;
        }
      }

      .sidebarMenuItemName {
        display: inline-block;
        text-decoration: none;
        color: inherit;
        font-size: 16px;
        font-weight: 400;
        line-height: 18.75px;
      }
    }
  }
}

@import 'src/styles/colorVariables.scss';

.mapContent {
  display: flex;
  flex: 1;
  position: relative;
  padding-top: 32px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 50%;
  justify-content: space-between;
  padding-right: 1.6rem;
  max-width: 160px;
}

.additionalText {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.block {
  padding-bottom: 24px;
}

.blockName {
  color: $regent-gray;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
}

.blockTitle {
  color: $shark;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.01em;
  padding-top: 2px;
}

.map {
  flex: 1;
  height: 100%;
  border: 1px solid $hawkes-blue;
  border-radius: 18px;
}

.mapButtons {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.mapButton {
  width: 30px;
  height: 30px;
  background-color: $malibu;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:not(:last-child) {
    margin-bottom: 5px;
  }
}

.mapButtonDisabled {
  background-color: $grey-200;
  pointer-events: none;
}

.mapButtonSvg {
  width: 20px;
  height: 20px;
}

.tooltip {
  max-height: 480px;
  overflow-y: auto;
}

.ball {
  border-radius: 50%;
  height: 8px;
  margin-right: 4px;
  width: 8px;
  display: inline-block;
}

.blue {
  background-color: $malibu;
}

.red {
  background-color: $burning-orange;
}

.grey {
  background-color: $grey-200;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
}

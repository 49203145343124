@import 'src/styles/colorVariables.scss';

.loginContainer {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card {
  width: 490px;
}

.title {
  font-size: 30px;
  font-family: Manrope-700;
  line-height: 41px;
  color: $shark;
}

.subtitle {
  font-size: 14px;
  font-family: Roboto;
  color: $manatee;
  font-weight: 400;
  line-height: 24px;
  padding-top: 8px;
}

.form {
  padding-top: 24px;
}

.inputContainer {
  padding-top: 16px;
}

.label {
  font-family: Manrope-600;
  font-size: 14px;
  line-height: 22px;
  color: $shark;
  margin-bottom: 8px;
}

.input {
  display: block;
  width: 100%;
  border-radius: 8px;
  color: $manatee;
  background-color: $white;
  height: 48px;
  border: 1px solid $hawkes-blue;
  padding: 13px;
  font-family: Manrope-600;
  font-size: 12px;
  line-height: 22px;
  font-weight: 600;
}

.passwordWrapper {
  position: relative;
}

.passwordVisibilityButton {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.passwordVisibilityIcon {
  width: 16px;
  height: 16px;
  color: $black;
}

.additionalOptions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.checkboxLabel {
  font-family: Inter-500;
  line-height: 14px;
  font-size: 12px;
  margin-left: 4px;
}

.forgotPasswordLink {
  color: $malibu;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-decoration: none;
}

.submit {
  height: 48px;
  width: 100%;
  color: $white;
  font-size: 12px;
  font-family: Manrope-600;
  background-color: $malibu;
  margin-top: 24px;

  &:focus {
    background-color: $malibu;
    color: $white;
  }
}

.signUpLink {
  font-family: Roboto;
  color: $manatee;
  line-height: 24px;
  cursor: pointer;

  a {
    font-weight: 500;
  }
}

.checkBox {
  display: flex;
  align-items: center;
}

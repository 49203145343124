@import 'src/styles/colorVariables.scss';
@import 'src/styles/mixins.scss';

.clampedParagraph {
  display: flex;
  cursor: pointer;
}

.paragraph {
  min-width: 0;
  padding-right: 4px;
  flex: 1;
}

.clamp {
  @include ellipsis;
}

.arrow {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  transition: all 200ms ease-in-out;
}

.rotate {
  transform: rotate(180deg);
}

@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin maxCharacters($chars: 20) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: $chars * 1ch;
}

@mixin clamp-lines($lines: 1) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

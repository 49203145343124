.backNavigationButton {
  display: flex;
  margin-bottom: 32px;
  cursor: pointer;
  align-items: center;
}

.linkText {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  margin-left: 16px;
}

@import 'src/styles/colorVariables.scss';

.addNewCard {
  border-radius: 18px;
  background-color: transparent;
  border: 2px dashed $fog;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-height: 278px;
  min-width: 570px;
}

.addNewCardInner {
  display: flex;
  align-items: center;
}

.text {
  color: $malibu;
  font-size: 16px;
  font-weight: 500;
  margin-left: 8px;
}

@import 'src/styles/variables.scss';
@import 'src/styles/colorVariables.scss';
@import 'src/styles/mixins.scss';

.headerCell {
  @include ellipsis;

  color: $grey-100;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.12px;
}

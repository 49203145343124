@import 'src/styles/colorVariables.scss';

.mostMentionsLegend {
  display: flex;
  flex-direction: column;
  gap: 18px;
  flex: 1;
  overflow: auto;
  padding-right: 10px;

  &::-webkit-scrollbar-track {
    background: $hawkes-blue;
    border-radius: 26px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c8caff;
    border-radius: 26px;
  }

  &::-webkit-scrollbar {
    width: 3px;
  }
}

.item {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.ball {
  border-radius: 50%;
  height: 14px;
  width: 14px;
  margin-right: 14px;
  transition: background-color 0.35s ease;
}

.name {
  padding: 0;
  margin: 0 auto 0 0;
  font-family: 'Roboto', sans-serif, Helvetica;
  color: $shark;
  font-size: 14px;
  line-height: 16.4px;
  font-weight: 400;
}

.value {
  padding: 0;
  margin: 0 4px 0 0;
  font-family: 'Roboto', sans-serif, Helvetica;
  color: $shark;
  font-size: 14px;
  line-height: 16.4px;
  font-weight: 500;
}

.loudspeakerIcon {
  width: 16px;
  height: 16px;
}

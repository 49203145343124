@import 'src/styles/colorVariables.scss';
@import 'src/styles/mixins.scss';

.horizontalContainer {
  max-width: 1170px;
  margin: 0 auto;
}

.chartWide {
  max-width: 1170px;
  margin: 15px auto;
  background: $white;
  border-radius: 24px;
  min-height: 487px;
  overflow: hidden;
  padding: 24px 24px 10px 24px;
}

.chartContainer {
  height: 100%;
  width: 100%;
  display: flex;
}

.navMenu {
  width: 200px;
  height: 420px;
  margin: 40px 15px 15px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.submenuInner {
  width: 100%;
  max-height: 248px;
  overflow-y: auto;
}

.submenuItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 14px;
  cursor: pointer;
}

.submenuLabel {
  @include ellipsis;

  padding-left: 8px;
}

.chartWrapper {
  height: 420px;
  margin-top: 30px;
  width: 850px;
}

@import 'src/styles/colorVariables.scss';

.form {
  position: relative;

  .card {
    background: linear-gradient(
        258.94deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%,
        #f3f1ff 100%
      ),
      rgba(255, 255, 255, 0.6);
    border-radius: 18px;
    padding: 16px 20px;

    hr {
      margin: 16px 0px;
      color: $manatee;
    }

    .formRow {
      display: flex;
      align-items: center;
      justify-content: space-between;

      label {
        width: 22.3%;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.01em;
        color: $shark;
      }

      .inputWrapper {
        width: 77.7%;
        position: relative;

        input {
          width: 100%;
          height: 48px;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.01em;
          color: $shark;
          border-width: 1px;
          border-style: solid;
          transition:
            color 0.15s ease-in-out,
            border-color 0.15s ease-in-out;

          &.enabled {
            padding: 16px;
            background: $white;
            border-radius: 10px;
            border-color: $hawkes-blue;

            &.error {
              color: $red-orange;
              border-color: $red-orange;
            }

            &:not(.error):focus {
              border-color: $hptt-sp-1;
            }
          }

          &.disabled {
            padding: 0;
            border-color: transparent;
            background-color: transparent;
          }
        }

        .visibilityButton {
          position: absolute;
          inset: 4px 4px 4px auto;
        }
      }
    }
  }

  .buttonsRow {
    width: 100%;
    position: absolute;
    top: -63px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    z-index: 1;
  }

  .buttonGroup {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .accountImage {
    align-items: center;
    background-color: $malibu;
    border: 1px solid $malibu;
    border-radius: 50%;
    color: $white;
    display: inline-flex;
    height: 48px;
    justify-content: center;
    width: 48px;
  }

  .profileImage {
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }

  .imageRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .leftSide {
    display: flex;
    align-items: center;
  }

  .imageText {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-left: 12px;
  }

  .upperText {
    color: #000;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.14px;
  }

  .lowerText {
    color: #86949d;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.12px;
  }

  .imageButtons {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .changeBtn,
  .uploadImageBtn {
    width: fit-content;
    padding: 10px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: #8080ff;
    color: #fff;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.14px;
  }

  .removeBtn {
    width: fit-content;
    padding: 10px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid #eef1fe;
    background: #fff;
    color: #25262d;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.14px;
  }
}

@import 'src/styles/colorVariables.scss';
@import 'src/styles/mixins.scss';

.mostMentionsSection {
  backdrop-filter: blur(4px);
  background: linear-gradient(
    258.94deg,
    rgba(255, 255, 255, 0.6) 0%,
    rgba(255, 255, 255, 0) 100%,
    rgba(243, 241, 255, 0.6) 100%,
    rgba(255, 255, 255, 0.2)
  );
  border-radius: 24px;
  margin: 15px 25px 15px 0;
  padding: 24px;
  width: 370px;
  height: 436px;
  display: flex;
  flex-direction: column;
  align-self: stretch;
}

.chartContainer {
  width: 100%;
  height: 172px;
  position: relative;
}

.chartInfo {
  position: absolute;
  top: 120px;
  left: 50%;

  transform: translateX(-50%);
}

.chartTitle {
  color: $shark;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.chartSubTitle {
  color: $shark;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
}

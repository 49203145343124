@import 'src/styles/colorVariables.scss';

.sectionTabs {
  position: relative;
  display: flex;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: rgba(200, 202, 255, 0.5);
  }
}

.sectionTabsWrapper {
  display: flex;
  align-items: center;
}

.tab {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.16px;
  display: flex;
  align-items: center;
  padding-bottom: 22px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border-bottom: 2px solid transparent;
  color: $regent-gray;

  & + & {
    margin-left: 28px;
  }
}

.active {
  color: $shark;
  border-bottom: 2px solid $shark;
}

.tooltip {
  cursor: pointer;
  display: inline-flex;
  margin-left: 8px;
}

.loaderRing {
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
  margin-left: auto;
}

.loaderRing div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  border: 1px solid $black;
  border-radius: 50%;
  animation: loader-spinning 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.loaderRing div:nth-child(1) {
  animation-delay: -0.375s;
}

.loaderRing div:nth-child(2) {
  animation-delay: -0.25s;
}

.loaderRing div:nth-child(3) {
  animation-delay: -0.125s;
}

.loaderRing.black div {
  border-color: $black transparent transparent transparent;
}

.loaderRing.white div {
  border-color: $white transparent transparent transparent;
}

@keyframes loader-spinning {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@import 'src/styles/colorVariables.scss';
@import 'src/styles/variables.scss';

.renameCampaignModalContent {
  min-width: 320px;
}

.label {
  display: flex;
  margin-bottom: 16px;
  padding: 0;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: $shark;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
}

.cancel {
  height: 40px;
  padding: 0 16px;
  font-weight: 500;
  color: $shark;
  margin-right: 16px;
  transition: all 0.3s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
}

@import 'src/styles/colorVariables.scss';

.button {
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:focus-visible {
    outline: 0;
  }

  &.rect {
    padding: 9px 15px;
  }

  &.square {
    padding: 11px;
  }

  &.transparent {
    background-color: transparent;
    border-color: transparent;
    color: $shark;
    font-weight: 400;

    &:hover,
    &:active,
    &:focus-visible {
      border-color: $shark;
    }
  }

  &.malibu {
    background-color: $malibu;
    border-color: $malibu;
    color: $white;

    &:hover,
    &:active,
    &:focus-visible {
      background-color: rgb(147, 147, 255);
    }

    &:focus-visible {
      box-shadow: 0 0 0 3px rgba(109, 109, 217, 0.5);
    }
  }

  &.white {
    background-color: $white;
    border: 1px solid $hawkes-blue;
    color: $shark;
    font-weight: 400;

    &:hover,
    &:active,
    &:focus-visible {
      border-color: $hawkes-blue;
    }
  }
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

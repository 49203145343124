@import 'src/styles/colorVariables.scss';

$timing: 0.1s;

.full-width {
  display: block;
  width: 100%;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-margin {
  padding: 0 !important;
  margin: 0 !important;
}

.b-radius-8 {
  border-radius: 8px;
}

.btn.btn-ghost:focus {
  outline: none !important;
  box-shadow: none !important;
}

.Toastify__toast-body {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.Toastify__toast--success {
  background: $hawkes-blue !important;
  border: 1px solid $malibu !important;
  border-radius: 12px !important;

  .Toastify__toast-body {
    color: $malibu;
  }
}

.Toastify__toast--error {
  border-radius: 12px !important;
  background-color: $seashell !important;
  border: 1px solid $burning-orange;

  .Toastify__toast-body {
    color: $burning-orange;
  }
}

.react-tagsinput {
  border: 1px solid $hawkes-blue !important;
  border-radius: 10px;
  min-height: 68px;
  padding: 12px !important;
  width: 100%;
  cursor: text;

  &:focus {
    border: 1px solid $periwinkle;
    border-color: $periwinkle !important;
  }

  > span {
    display: flex;
    flex-wrap: wrap;
  }

  .tags-input {
    margin: 0;
    padding: 0;
    width: 100%;
    border: none !important;

    &:focus {
      outline: none;
    }
  }

  .react-tagsinput-tag {
    border-radius: 10px;
    border: none !important;
    color: $white;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    margin-right: 6px;
    padding: 8px 10px;

    &.grouped {
      margin-right: 0px;
      border-radius: 0px;
      position: relative;

      &::after {
        content: '';
        width: 1px;
        height: 60%;
        position: absolute;
        right: 0;
      }

      &.left {
        border-radius: 10px 0 0 10px;
      }

      &.right {
        margin-right: 6px;
        border-radius: 0 10px 10px 0;

        &::after {
          width: 0px;
        }
      }

      &.left.right {
        border-radius: 10px;
      }
    }
  }

  a.react-tagsinput-remove {
    color: $white;
    text-decoration: none;
    margin-left: 10px;
    font-weight: 400;
  }
}

.react-tags-ig {
  .react-tagsinput {
    span > span.react-tagsinput-tag:before {
      content: '@';
    }
  }
}

.react-tagsinput {
  .react-tagsinput-tag {
    background-color: $malibu !important;

    &.grouped::after {
      background-color: $melrose;
    }
  }
}

.add-new-theme-negative-keys {
  .react-tagsinput {
    .react-tagsinput-tag {
      background-color: $burning-orange !important;

      &.grouped::after {
        background-color: $apricot;
      }
    }
  }
}

.rc-tooltip {
  background-color: transparent;
  opacity: 1;

  .rc-tooltip-content {
    .rc-tooltip-inner {
      padding: 0;
      background-color: transparent;
      border: none;
      border-radius: unset;
      min-height: 0;
    }
  }

  &.rc-tooltip-placement-top {
    .rc-tooltip-arrow {
      border-top-color: $malibu !important;
    }
  }

  .rc-tooltip-placement-left {
    .rc-tooltip-arrow {
      border-left-color: $malibu !important;
    }
  }

  .rc-tooltip-placement-right {
    .rc-tooltip-arrow {
      border-right-color: $malibu !important;
    }
  }

  &.rc-tooltip-placement-bottom {
    .rc-tooltip-arrow {
      border-bottom-color: $malibu !important;
    }
  }
}

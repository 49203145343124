@import 'src/styles/colorVariables.scss';
@import 'src/styles/mixins.scss';

.sentimentAnalysesChart {
  display: flex;
  height: 100%;
  position: relative;
  width: 100%;
}

.inner {
  height: 90%;
  margin-top: 30px;
  width: 100%;
  position: relative;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  letter-spacing: 0.01em;
}

.label {
  position: absolute;
  top: 50%;
  left: 233px;
  transform: translate(-50%, -50%);
}

.rightWrapper {
  height: 380px;
  overflow: hidden;
  position: absolute;
  right: 30px;
  top: 50px;
  width: 47%;
}

.input {
  width: 100%;
  height: 50px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #eef1fe;
  background: #fff;
  color: var(--Black, #25262d);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 105%; /* 14.7px */
  letter-spacing: 0.14px;
  padding: 18px 21px;
  margin-top: 18px;
}

.input:focus {
  border-color: #c8caff;
}

.deleteButton {
  position: absolute;
  right: 17px;
  bottom: 0;
  transform: translateY(-100%);
  border: none;
  background: transparent;
  cursor: pointer;
}

.sidebarMenuItemIcon {
  display: flex;
  width: 16px;
  height: 16px;
  padding: 1.333px 2px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.inputLabel {
  color: var(--Black, #25262d);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.14px;
}

.newFieldLabel {
  display: block;
  color: var(--Gray, #86949d);
  text-align: right;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 105%; /* 14.7px */
  letter-spacing: 0.14px;
  margin-top: 18px;
}

.debounceContainer {
  position: absolute;
  width: 100%;
  max-height: 165px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  border-radius: 10px;
  border: 1px solid #c8caff;
  background: #fff;
  z-index: 10;
  padding-right: 8px;
}

.debounceContainer::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

.debounceContainer::-webkit-scrollbar-thumb {
  border-radius: 26px;
  background: var(--3, #c8caff);
}

.debounceContainer::-webkit-scrollbar-track {
  border-radius: 30px;
  background: #eef1fe;
}

.placeContainer {
  width: 100%;
  padding: 14px 10px 14px 14px;
  display: flex;
  gap: 8px;
}

.imageContainer {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}

.placeInfoContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.placeName {
  color: var(--text, #25262d);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.14px;
}

.placeAddress {
  color: var(--Text-sec, #86949d);
  /* Roboto regular - 14px */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
}

@import 'src/styles/colorVariables.scss';

.edit {
  display: flex;
  align-items: center;
}

.headerSpanTitle {
  font-weight: 500;
}

.iconContainer {
  height: 20px;
  margin: 0 10px;
  cursor: pointer;
}

.input {
  width: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  line-height: 19px;
  padding: 2px;
  letter-spacing: 0.01em;
  font-weight: 500;
  color: $shark;
  border: 1px solid rgba($shark, 0.2);
  background-color: transparent;
  border-radius: 4px;
}

.accept {
  width: 26px;
  height: 26px;

  &:hover {
    opacity: 0.8;
  }

  path {
    stroke: $malibu;
  }
}

.discard {
  width: 24px;
  height: 24px;

  &:hover {
    opacity: 0.8;
  }

  path {
    fill: $burning-orange;
  }
}

@import 'src/styles/colorVariables.scss';

.locationChartSection {
  background: $white;
  border-radius: 24px;
  height: 436px;
  margin: 15px 25px 15px 0;
  padding: 24px;
  width: 770px;
  display: flex;
  flex-direction: column;
}

.fullWidth {
  width: 100%;
  margin-right: 0;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.filterButton {
  margin-left: auto;
}

@import 'src/styles/colorVariables.scss';

.timezone {
  padding: 0;
  width: 100%;

  & > div:nth-of-type(1) {
    height: 48px;
    border-radius: 10px;
    border-color: $hawkes-blue;
  }

  & > div:nth-of-type(2) {
  }
}

@import 'src/styles/colorVariables.scss';
@import 'src/styles/mixins.scss';

.campaignCard {
  padding: 20px;
  background-color: $white;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.cardTitleRow {
  display: flex;
  justify-content: space-between;
}

.campaignNameAndCategories {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.campaignName {
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
}

.categories {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: $regent-gray;
}

.dropdownButton {
  padding: 4px 12px 8px 12px;
  border-radius: 12px;
  border: 1px solid transparent;
  transition: border-color 0.15s ease-in-out;
  user-select: none;

  &:focus,
  &:active {
    border: 1px solid black;
  }

  img {
    pointer-events: none;
  }
}

.statusTag {
  display: flex;
}

.content {
  width: 100%;
  justify-content: flex-start;
  display: flex;
  padding-top: 24px;

  .contentWrapper {
    flex: 1;
    padding-right: 40px;
  }
}

.keywordsTitle {
  font-weight: 400;
}

.keywordsCount {
  font-weight: 500;
}

.mentionsAndTopSources {
  display: flex;
  padding-top: 16px;
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
}

.title {
  width: 100%;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  color: $regent-gray;
}

.section {
  min-width: 0;
}

.topSources {
  width: 100%;
}

.sectionListItem {
  @include ellipsis();

  min-width: 0;
  padding-top: 8px;
  display: flex;
  align-items: center;

  .sourceIcon {
    margin-right: 6px;

    svg {
      width: 14px;
      height: 14px;
    }
  }

  .label {
    @include maxCharacters(25);

    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: $shark;
  }

  .labelCount {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
  }

  .loudSpeakerIcon {
    width: 15px;
    height: 15px;
  }
}

.chartWrapper {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dates {
  display: flex;
  font-size: 12px;
  line-height: 14px;
  color: $regent-gray;
  padding-top: 32px;

  span {
    margin: 0 8px;
  }
}

.dropdown {
  position: relative;
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  right: 0;
  min-width: 96px;
  padding: 8px 0;
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.dropdownItem {
  width: 100%;
  height: 40px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  line-height: 16.5px;
  color: $regent-gray;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out;

  &:hover {
    background-color: $malibu-10;
  }
}

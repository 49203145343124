@import 'src/styles/colorVariables.scss';

.calendarDetails {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 24px;
  height: 436px;
  margin: 15px 0;
  padding: 24px;
  width: 770px;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 55px;
}

.navigation {
  border-bottom: 1px solid $hawkes-blue;
}

.lists {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex: 1;
}

.mentions {
  min-width: 140px;
  max-width: 33%;
}

.sources {
  min-width: 112px;
  max-width: 33%;
}

.campaigns {
  min-width: 90px;
  max-width: 33%;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
}

@import 'src/styles/colorVariables.scss';
@import 'src/styles/variables.scss';

.chooseCampaignModalContent {
  min-width: 512px;
}

.modalHeader {
  padding: 16px;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  border-bottom: 1px solid $hawkes-blue;
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 8px;
}

.modalBody {
  max-height: 42vh;
  overflow-y: scroll;
  padding-top: 16px;

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
}

.listItem {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: $hawkes-blue;
  }
}

.listItemActive {
  background-color: $hawkes-blue;
}

.modalFooter {
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid $hawkes-blue;
}

.cancel {
  margin-right: 16px;
}

@import 'src/styles/colorVariables.scss';

.calendar {
  background: $white;
  border-radius: 24px;
  height: 436px;
  margin: 15px 25px 15px 0;
  padding: 24px;
  width: 370px;
  position: relative;
}

.navigation {
  padding: 0 24px 8px;
}

.legend {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 16px;
  left: 0;
}

.legendItem {
  display: flex;
  align-items: center;

  & + & {
    margin-left: 24px;
  }
}

.rect {
  width: 14px;
  height: 14px;
  border-radius: 5px;
  margin-right: 14px;
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 8px;
    height: 2px;
    border-radius: 0.6px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.rectBlue {
  background-color: rgba($malibu, 0.2);

  &::after {
    background-color: $malibu;
  }
}

.rectRed {
  background-color: rgba($burning-orange, 0.2);

  &::after {
    background-color: $burning-orange;
  }
}

.label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
}

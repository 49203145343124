@import 'src/styles/colorVariables.scss';

.radarChartComponent {
  background: #fff;
  border-radius: 24px;
  height: 436px;
  margin: 15px 0;
  padding: 24px;
  width: 370px;
}

.insufficientData {
  margin: 172px auto;
  color: $shark;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.01em;
}

.tooltip {
}

.ball {
  border-radius: 50%;
  height: 8px;
  margin-right: 4px;
  width: 8px;
  display: inline-block;
}

@import 'src/styles/colorVariables.scss';
@import 'src/styles/mixins.scss';

.mostMentionItem {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.14px;
}

.pos,
.positive {
  color: $malibu;
}

.neg,
.negative {
  color: $burning-orange;
}

.neu,
.neutral {
  color: $grey-200;
}

@import 'src/styles/colorVariables.scss';
@import 'src/styles/mixins.scss';

.horizontalContainer {
  display: flex;
  justify-content: space-between;
  max-width: 1170px;
  margin: 0 auto;
}

.container {
  margin: 0 auto;
  padding: 8px;
  padding-bottom: 48px;
}

@import 'src/styles/colorVariables.scss';

.campaignsChart {
  width: 122px;
  height: 122px;
  position: relative;
}

.label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

@import 'src/styles/colorVariables.scss';

.campaigns {
  margin: 0 auto;
  max-width: 1169px;
  min-height: 100vh;
  padding: 0px 0px 50px;
  position: relative;
}

.sectionRow {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 72px;
}

.mainCard {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  backdrop-filter: blur(4px);
  background: linear-gradient(
    90deg,
    rgba($malibu, 0.1) 0%,
    rgba($malibu, 0.5) 90%
  );
  background-color: $periwinkle;
  box-shadow: 57px 47px 99px rgba(0, 0, 0, 0.04);
  height: 136px;
  padding: 0 20px;
  border-radius: 24px;
}

.mainCardInnerLeft {
  height: 100%;
  color: $malibu;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainCardLink {
  margin-left: 104px;
  height: 40px;
  text-decoration: none;
  color: $malibu;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  background-color: $white;
  border-radius: 12px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;

  &:hover {
    opacity: 0.8;
    color: $malibu;
  }

  img {
    display: flex;
    margin-right: 8px;
  }
}

.mainCardLeftCampaignChartImage {
  position: absolute;
  bottom: 0;
  right: 40px;
}

.statusFilterItemsWrapper {
  display: flex;
  border-bottom: 2px solid rgba($periwinkle, 0.5);
  height: 45px;
  margin-top: 40px;
}

.statusFilterList {
  height: 100%;
  display: flex;
  margin: 0;
  padding: 0;
}

.statusFilterListItem {
  display: flex;
  font-size: 14px;
  height: 100%;
  color: $regent-gray;
  width: 110px;
  margin: 0 24px 0 0;
  padding: 0;
  transition: all 0.3s ease-in-out;
  border-bottom: 2px solid transparent;
}

.statusFilterListItemActive {
  color: $shark;
  border-color: $shark;
}

.statusFilterListItemButton {
  display: flex;
  align-items: center;
  font-weight: 500;
  height: 100%;
  line-height: 16px;
  width: 100%;
}

.statusFilterListItemButtonDraft {
  margin-right: 8px;
}

.statusFilterListItemButtonTag {
  margin-left: 8px;
}

.searchWrapper {
  margin-left: auto;
  display: flex;
  align-items: center;
  padding-bottom: 12px;
}

.filterButton {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $malibu;
  font-size: 14px;
  margin-left: 16px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;

  &:hover {
    opacity: 0.8;
  }

  img {
    display: flex;
    width: 30px;
    height: 30px;
    margin-left: 8px;
  }
}

.cards {
  min-height: 75vh;
}

.cardRow {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 30px;
  width: 100%;
  margin: 32px 0;
}

.empty {
  padding: 48px;
}

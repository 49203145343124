@import 'src/styles/variables.scss';
@import 'src/styles/colorVariables.scss';
@import 'src/styles/mixins.scss';

.keywordAnalyses {
  margin: 16px auto 0;
  max-width: 1170px;
  display: flex;
  flex-direction: column;
}

.tableContent {
  width: 100%;
  margin-top: 40px;
  max-height: 544px;
}

.tableContainer {
  max-height: 544px;
  padding-right: 20px;
}

.headerCell {
  @include ellipsis;

  color: $grey-100;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.12px;
}

.nameCell {
  color: $black;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.16px;
  padding-right: 60px;
}

.filterButton {
  margin-left: auto;
}

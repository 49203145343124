@import 'src/styles/colorVariables.scss';
@import 'src/styles/variables.scss';

.overlay {
  z-index: $z-index-modal;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba($shark, 0.5);
  overflow-y: scroll;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 80vh;
}

@import 'src/styles/colorVariables.scss';

.searchInput {
  position: relative;
}

.searchIcon {
  position: absolute;
  top: 10px;
  left: 10px;
}

.searchInputField,
.searchInputField:focus {
  outline-color: $hawkes-blue !important;
  border-color: $hawkes-blue !important;
  transition: none;
  border: none;
  outline: none;
  transition: none;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  transition: none !important;
  display: block;
  width: 100%;
  padding: 0 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  color: $shark;
  background-color: $white;
  background-clip: padding-box;
  border: 1px solid $hawkes-blue;
  appearance: none;
  border-radius: 10px;
  height: 38px;
  text-indent: 25px;
}

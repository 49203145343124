@import 'src/styles/colorVariables.scss';
@import 'src/styles/mixins.scss';

.positivityLegend {
  align-items: center;
  display: inline-flex;
  justify-content: space-between;
}

.positivityLegendItem {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-right: 24px;
}

.positivityLegendItem:last-child {
  padding-right: 0;
}

.ball {
  border-radius: 50%;
  height: 8px;
  margin-right: 14px;
  width: 8px;
}

.positive {
  background-color: $malibu;
}

.negative {
  background-color: $burning-orange;
}

.neutral {
  background-color: $grey-200;
}

.text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.01em;
}

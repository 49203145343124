@import 'src/styles/colorVariables.scss';

.tooltip {
  background-color: $malibu;
  border-radius: 8px;
}

.overlay {
  max-width: 320px;
  padding: 16px;
  color: $white;
  background-color: $malibu;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
}

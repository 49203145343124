@import 'src/styles/colorVariables.scss';
@import 'src/styles/variables.scss';

.mainContainer {
  background-color: $hawkes-blue;
  height: 100%;
  min-height: 100vh;
  display: flex;
  position: relative;
}

.mainContainerInner {
  width: 100%;
  margin-left: 80px;
  transition: margin-left linear 0.2s;
  margin-left: 205px;
}
